<template>

	<div class="cont" id="p08">
		<div class="page-title">{{ program.name }}</div>

		<div class="c01">
			<div class="s01">
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">기본정보</div>
						
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">아이디</div>
							<div class="si-desc text-right">{{ item.basic.userId }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">비밀번호</div>
							<div class="si-desc">
								<a @click="viewPassword" class="float-right">
									<div class="s-link" style="color: green;">비밀번호 변경<i class="xi-external-link"></i></div>
								</a>
							</div>
						</div>
						<div class="s-content">
							<div class="si-title">가입일</div>
							<div class="si-desc text-right">{{ item.basic.joinDate | transDate }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">계정 상태</div>
							<div class="si-desc text-right">
								<span :class="item.basic.status">정상 사용</span>
							</div>
						</div>
						<div class="s-content">
							<div class="si-title">사업규모</div>
							<div class="si-desc text-right">{{ bizSizeName }}</div>
						</div>
					</div>
				</div>
				<div class="s-item" style="padding-bottom: 0;">
					<div class="s-header">
						<div class="s-title">사업자정보</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">사업자 구분</div>
							<div class="si-desc">{{ item.company.companyTypeName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">상호명</div>
							<div class="si-desc">{{ item.company.businessName}}</div>
						</div>
						<div class="s-content">
							<div class="si-title">사업자등록번호</div>
							<div class="si-desc">{{ item.company.businessNumber}}</div>
						</div>
						<div class="s-content">
							<div class="si-title">업태/업종</div>
							<div class="si-desc">{{ item.company.businessTypeCate }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">사업장 주소</div>
							<div class="si-desc">{{ item.company.addressMain }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">상세 주소</div>
							<div class="si-desc">{{ item.company.addressDetail }}</div>
						</div>
					</div>
				</div>
				<div class="s-item" style="padding-bottom: 0;">
					<div class="s-header">
						<div class="s-title">대표자 정보</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">대표자 성명</div>
							<div class="si-desc">{{ item.company.ceoName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">대표자 연락처</div>
							<div class="si-desc">{{ item.company.ceoMobilePhoneNumber }}</div>
						</div>
					</div>
				</div>
				<div class="s-item hide">
					<div class="s-header">
						<div class="s-title">담당자</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">담당자</div>
							<div class="si-desc">
								<input type="text" placeholder="담당자 이름을 입력하세요"
									v-model="item.manger.managerName"
									:rules="[rules.max(item.manger, 'managerName', 20)]"
								>
							</div>
						</div>
						<div class="s-content">
							<div class="si-title">핸드폰</div>
							<div class="si-desc flex">
								<select class="mr-5" name="" id=""
									v-model="managerMobilPhoneNumber.fir"
								>
									<option 
										v-for="(number, index) in numbers"
										:key="index"
										:value="number">{{ number }}</option>
								</select>
								<input class="mr-5" placeholder=""
									v-model="managerMobilPhoneNumber.sec"
									type="number"
									:rules="[rules.max(managerMobilPhoneNumber, 'sec', 4)]"
								>
								<input class="" placeholder=""
									v-model="managerMobilPhoneNumber.thi"
									type="number"
									:rules="[rules.max(managerMobilPhoneNumber, 'thi', 4)]"
								>
							</div>
						</div>
						<div class="s-content">
							<div class="si-title">이메일</div>
							<div class="si-desc">
								<input type="email" placeholder="이메일 주소를 입력하세요"
									v-model="item.manger.managerEmail"
									maxlength="50"
								>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="s01">
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">이용 서비스</div>
					</div>
					<div class="s-body">
						<div class="table type11">
							<div class="item-header">
								<div class="item">
									<div class="td">서비스</div>
									<div class="td">공급가</div>
								</div>
							</div>
							<div class="item-list">
								<div class="item" 
									v-for="( payment, index) in item.paymentItems"
									:key="index"
								>
									<div class="td">{{ payment.serviceItemName }}</div>
									<div class="td">{{ payment.merchantCommission }}%</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">정산</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">정산은행</div>
							<div class="si-desc">{{ item.settlement.bankCodeName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">계좌번호</div>
							<div class="si-desc">{{ item.settlement.bankAccountNumber }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">예금주</div>
							<div class="si-desc">{{ item.settlement.bankAccountHolder }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="s03">
				<a @click="$router.go(-1)"><div class="btn-save">확인</div></a>
			</div>
		</div>
		
		<MerchantDetailPopupPassword 
			v-if="showPopup.password"
			:item="item"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		/>
		
		</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { rules } from '@/resources/rules/rules'
	import { filter } from '@/resources/filter/filter'
	
	import MerchantDetailPopupPassword from '@/view/Merchant/MerchantDetailPopupPassword'
	
	export default{
		name: "AgentItem"
		,props: ['user']
		,components: { MerchantDetailPopupPassword }
		,data: function(){
			return {
				program: {
					name: this.$language.menu[4].list[0].name
				}
				,item: {
					basic: {}
					,manger: {}
					,company: {}
					,settlement: {}
					,paymentItems: {}
				}
				,managerMobilPhoneNumber: { fir: '010', sec: '', thi: ''}
				,index: this.$route.params.index
				,numbers: ['010', '011', '019']
				,showPopup: {
					password: false
					,account: false
				}
				,rules:rules
			}
		}
		,filter: filter
		,computed: {
			bizSizeName: function(){
				let bizSizeName = ''
				switch (this.item.company.bizSize){
					default:
						bizSizeName = '-'
						break;
					case "0":
						bizSizeName = '영세'
						break;
					case "1":
						bizSizeName = '중소1'
						break;
					case "2":
						bizSizeName = '중소2'
						break;
					case "3":
						bizSizeName = '중소3'
						break;
					case "4":
						bizSizeName = '일반'
						break;
				}

				return bizSizeName
			}
		}
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/me'
						,authorize: true
					})
					
					if(result.success){
						this.item = result.data.content
						
						this.item.manger = {}
						
						if(this.item.manger.managerMobilPhoneNumber){
							this.$set(this.managerMobilPhoneNumber, 'fir', this.item.manger.managerMobilPhoneNumber.substring(0, 3))
							this.$set(this.managerMobilPhoneNumber, 'sec', this.item.manger.managerMobilPhoneNumber.substring(3, 7))
							this.$set(this.managerMobilPhoneNumber, 'thi', this.item.manger.managerMobilPhoneNumber.substring(7, 12))
						}
						
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E.status})
				}
			}
			,save: async function(){
				try{
					const result = await Axios({
						method: 'post'
						,url: '/me'
						,data: {
							managerEmail: this.item.manger.managerEmail
							,managerName: this.item.manger.managerName
							,managerMobilPhoneNumber: this.item.manger.managerMobilPhoneNumber
						}
						,authorize: true
					})
					
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: result.data.message })
						this.getData()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,viewPassword: function(){
				this.showPopup.password = true
				this.$emit('setOverlay', true)
			}
			,viewAccount: function(){
				this.showPopup.account = true
				this.$emit('setOverlay', true)
			}
			,setOverlay: function(type){
				this.$emit('setOverlay', type)
				if(!type){
					this.showPopup.password = false
					this.showPopup.account = false
				}
			}
			,setNotify: function(option){
				this.$emit('setNotify', option)
			}
		}
		,created: function(){
			if(this.user.basic.level > 1){
				this.$router.push({ name: 'SalesDetail'})
			}else{
				this.getData()
			}
		}
		,watch: {
			managerMobilPhoneNumber: {
				deep: true
				,handler: function(call){
					this.item.manger.managerMobilPhoneNumber = call.fir + call.sec + call.thi
				}
			}
		}
	}
</script>

<style>
	.table.type11 .item-header .item .td {width: 50% !important; justify-content: center !important }
	.table.type11 .item-list .item .td {width: 50% !important; justify-content: center !important }
</style>





















